/**
 * 競合
 */
import { isBlank } from '@/utilities/typing'

class Competitor {
    competitor_id;
    department_id;
    competitor_name;
    priority;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.competitor_id = properties.competitor_id;
        this.department_id = properties.department_id;
        this.competitor_name = properties.competitor_name;
        this.priority = properties.priority;
    }
}

export default Competitor;
