<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - 競合</h1>
    </header>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label class="form-label">事業部</label>
                <form-select
                    v-model="selected_department_id"
                    :options="options_department"
                    :disabled="loading_count > 0"
                ></form-select>
            </div>
            <div class="col-6">
                <button class="btn btn-primary" @click="search()" :disabled="loading_count > 0">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <div v-else class="col-sm-36 col-md-26 col-lg-20">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-8">競合</th>
                        <th class="text-center col-2"></th>
                    </tr>
                </thead>
                <template v-if="competitors.length">
                    <draggable
                        v-model="competitors"
                        item-key="competitor_id"
                        tag="tbody"
                        handle=".handle"
                        @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="align-middle text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle"><form-input required v-model="element.competitor_name" /></td>
                                <td class="text-nowrap text-center">
                                    <button type="submit" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input required v-model="competitor_to_create.competitor_name" /></td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>


    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>

import draggable from 'vuedraggable';
import Department from '@/models/entities/department';
import Competitor from '@/models/entities/competitor';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';

export default {
    name: 'MasterCompetitor',
    components: {
        draggable,
        ConfirmDialog,
        InlineLoader,
        FormInput,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            loading_count: 0,
            selected_department_id: null,
            options_department: [],
            competitors: [],
            competitor_to_create: new Competitor(),
            competitor_to_remove: null,
        }
    },
    mounted() {
        this.fetchDepartments();
    },
    methods: {
        // 事業部一覧取得
        fetchDepartments() {
            // ローディング開始
            this.loading_count++;
            // option空に
            this.options_department.splice(0);

            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }

                // 初期値事業部セット
                this.selected_department_id = this.options_department[0].value;

                // 競合一覧取得
                this.search();
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 事業部セットされているか確認
            if (this.$helper.isBlank(this.selected_department_id)) {
                this.showErrorMessage('事業部を選択してください');
                return;
            }

            this.loading_count++;

            //表示データ初期化
            this.competitors.splice(0);

            this.$http.get('/master/competitor', {
                params: {
                    department: this.selected_department_id
                }
            })
            .then(response => {
                for (let row of response.data) {
                    this.competitors.push(new Competitor(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        //新規登録
        create() {
            if (this.$helper.isBlank(this.competitor_to_create.competitor_name)) {
                this.showErrorMessage("競合を入力してください");
                return;
            }

            this.startScreenLoading();

            // 事業部セット
            this.competitor_to_create.department_id = this.selected_department_id;

            this.$http.post('/master/competitor', this.competitor_to_create)
            .then(response => {
                // 登録されたデータをリストに追加
                this.competitors.push(new Competitor(response.data));
                // 追加行を初期化
                this.competitor_to_create = new Competitor();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        //保存
        update(competitor) {
            if (this.$helper.isBlank(competitor.competitor_name)) {
                this.showErrorMessage('競合を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/competitor/${competitor.competitor_id}`, competitor)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        //削除確認
        removeReady(competitor) {
            this.competitor_to_remove = competitor;
            this.$refs.confirmRemove.show();
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/competitor/${this.competitor_to_remove.competitor_id}`)
            .then(() => {
                // リストから削除
                let removed_index = this.competitors.findIndex(
                    (row) => row.competitor_id === this.competitor_to_remove.competitor_id
                );
                this.competitors.splice(removed_index, 1);
                // 値をリセット
                this.competitor_to_remove = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //ソート
        sort() {
            this.startScreenLoading();

            const competitors = this.competitors.map(competitor_object => competitor_object.competitor_id);

            this.$http.post('/master/competitor/sort', {
                competitors: competitors,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
